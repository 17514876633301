<template>
  <div class="side-bar-bg">
    <div class="brand-logo">
      <img src="../assets/logo.svg" alt="">
    </div>
    <div class="account-info">
      {{account}}, 您好
    </div>
    <div class="logout" @click="logout">
      登出
    </div>

    <div class="item" @click="toLottery">
      抽獎後台
    </div>

    <div class="item" @click="activeSection = '202204'">
      2022-04 <span class="non-select" :class="{'selected': activeSection === '202204'}"> > </span>
    </div>

    <div class="sub-item" v-if="activeSection === '202204'"  @click="toDashboard('202204')" :class="{active : this.pageName === '202204活動數據'}">
      活動數據
    </div>

    <div class="sub-item" v-if="activeSection === '202204'" @click="toParticipate('202204')" :class="{active : this.pageName === '202204參與名單'}">
      參與名單
    </div>

    <div class="item" @click="activeSection = '202208'">
      2022-08<span class="non-select" :class="{'selected': activeSection === '202208'}"> > </span>
    </div>

    <div class="sub-item " v-if="activeSection === '202208'" @click="toDashboard('202208')" :class="{active : this.pageName === '202208活動數據', }">
      活動數據
    </div>

    <div class="sub-item" v-if="activeSection === '202208'" @click="toParticipate('202208')" :class="{active : this.pageName === '202208參與名單'}">
      參與名單
    </div>

    <div class="item" @click="activeSection = '202211'">
      2022-11<span class="non-select" :class="{'selected': activeSection === '202211'}"> > </span>
    </div>

    <div class="sub-item " v-if="activeSection === '202211'" @click="toDashboard('202211')" :class="{active : this.pageName === '202211活動數據', }">
      活動數據
    </div>

    <div class="sub-item" v-if="activeSection === '202211'" @click="toParticipate('202211')" :class="{active : this.pageName === '202211參與名單'}">
      參與名單
    </div>
  </div>
</template>

<script>
export default {
  name:'side-bar',
  props:{
    pageName: null,
    activeSection: null
  },
  data(){
    return{
        account: "",
    }
  },
  created() {
    this.account = this.$auth.getAdminAccount()
  },
  mounted() {},
  methods:{
    toLottery(){
      window.location.href='https://tgl-cms.accuhit.com.tw/'
    },
    toDashboard(path){
      if(path === '202204'){
        this.$router.push({path:'202204dashboard'})
      } else if(path === '202208') {
        this.$router.push({path:'202208dashboard'})
      } else if(path === '202211') {
        this.$router.push({path:'202211dashboard'})
      }
    },
    toParticipate(path){
      if(path === '202204'){
        this.$router.push({path:'202204participate'})
      } else if(path === '202208') {
        this.$router.push({path:'202208participate'})
      } else if(path === '202211') {
        this.$router.push({path:'202211participate'})
      }
    },
    logout(){
        this.$auth.clearToken()
    }
  }
}
</script>

<style lang="scss" scoped>
  .side-bar-bg{
    width: 260px;
    background: #27327b;

    .brand-logo{
      padding: 0px;
      width: 80%;
      margin: 0 auto;
      img{
        margin-top: 50px;
        width: 100%;
      }
    }

    .account-info{
      color: white;
      text-align: center;
      margin-top: 50px;
    }

    .logout{
      background: white;
      color:#27327b;
      width: 60px;
      height: 30px;
      margin: 20px auto;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }

    .item{
      display: flex;
      justify-content: center;
      margin: 25px auto;
      padding: 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 3px;
      color: white;
      cursor: pointer;
      user-select: none;

      .non-select{
        position: relative;
        left: 2px;
        top: -2px;
        transform: rotate(270deg);
        transition: .2s linear;
      }

      .selected{
        transform: rotate(90deg) !important;
        top:  2px !important;
        left: 5px !important;
      }
    }

    .sub-item{
      display: flex;
      justify-content: center;
      margin: 25px auto;
      padding: 20px;
      font-size: 16px;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 3px;
      color: white;
      cursor: pointer;
      user-select: none;
      transition: .2s linear;
    }

    .active {
      background: #5386c0;
    }
  }
</style>
